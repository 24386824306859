<template>
  <el-container>
    <el-header height="90">
      <el-breadcrumb
        class="bread"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item :to="{ path: '' }"><a href="/exam/question">返回试题列表</a></el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>添加试题</span>
          <el-button style="float: right; padding: 10px" type="text" @click="submitQuestion">提交数据</el-button>
          <el-button style="float: right; padding: 10px" type="text" @click="clear">清空数据</el-button>
        </div>
        <div class="text item">
          <el-form ref="addQuForm" :model="addQuForm" :rules="addQuFormRules">
            <el-form-item label="所属科目" label-width="120px" prop="subjectId">
              <el-select v-model="addQuForm.subjectId" placeholder="请选择">
                <el-option
                  v-for="item in allSubject"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="试题类型" label-width="120px" prop="questionType">
              <el-select v-model="addQuForm.questionType" placeholder="请选择" @change="addQuForm.options = []">
                <el-option
                  v-for="item in questionType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="试题难度" label-width="120px" prop="questionLevel">
              <el-select v-model="addQuForm.questionLevel" placeholder="请选择">
                <el-option :value="parseInt(1)" label="简单" />
                <el-option :value="parseInt(2)" label="中等" />
                <el-option :value="parseInt(3)" label="困难" />
              </el-select>
            </el-form-item>
            <el-form-item label="试题内容" label-width="120px" prop="questionContent">
              <!--              <el-input
                              v-model="addQuForm.questionContent"
                              style="margin-left: 5px"
                              type="textarea"
                              :rows="5"
                            />-->
              <editor id="tinymce" v-model="addQuForm.questionContent" :init="init" />
            </el-form-item>
            <el-form-item label="试题解析" label-width="120px" prop="analysis">
              <el-input
                v-model="addQuForm.analysis"
                style="margin-left: 5px"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
            <div v-if="addQuForm.questionType <= 3">
              <el-button
                type="primary"
                plain
                size="small"
                icon="el-icon-plus"
                style="margin-left: 40px"
                @click="addOptionAnswer"
              >
                添加候选答案
              </el-button>
              <!-- 存放候选答案的表单 -->
              <el-form-item prop="options">
                <el-table key="optionAnswer" :data="addQuForm.options" border style="width: 96%;margin-left: 40px;margin-top: 10px">
                  <el-table-column label="是否答案" width="80" align="center">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.correct" @change="checked=>checkAnswer(checked,scope.row.id)">答案
                      </el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="选项" prop="pos" />
                  <el-table-column label="内容">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.content"
                        style="margin-left: 5px"
                        type="textarea"
                        :rows="5"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="选项解析">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.analysis"
                        style="margin-left: 5px"
                        type="textarea"
                        :rows="5"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-delete" circle @click="delAnswer(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
            <div v-else-if="addQuForm.questionType === 4">
              <el-form-item label="试题答案" label-width="120px" prop="option">
                <el-radio-group v-model="answerContent4" @change="onRadioChange">
                  <el-radio :label="0">正确</el-radio>
                  <el-radio :label="1">错误</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-else-if="addQuForm.questionType === 5">
              <el-button
                type="primary"
                plain
                size="small"
                icon="el-icon-plus"
                style="margin-left: 40px"
                @click="addAnswer"
              >
                添加答案
              </el-button>
              <!-- 存放答案的表单 -->
              <el-form-item prop="options">
                <el-table key="answer" :data="addQuForm.options" border style="width: 96%;margin-left: 40px;margin-top: 10px">
                  <el-table-column label="顺序" prop="pos" />
                  <el-table-column label="答案">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.content"
                        style="margin-left: 5px"
                        type="textarea"
                        :rows="2"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="答案解析">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.analysis"
                        style="margin-left: 5px"
                        type="textarea"
                        :rows="2"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-delete" circle @click="delAnswer(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
            <div v-else-if="addQuForm.questionType === 8">
              <el-button
                type="primary"
                plain
                size="small"
                icon="el-icon-plus"
                style="margin-left: 40px"
                @click="addQuTableVisible = true"
              >
                添加組题
              </el-button>
              <el-button
                type="primary"
                plain
                size="small"
                icon="el-icon-view"
                style="margin-left: 40px"
                @click="viewGroupQuestion"
              >
                查看組题
              </el-button>
            </div>
            <div v-else>
              <el-form-item label="参考答案" label-width="120px">
                <el-input
                  v-model="answerContent8"
                  style="margin-left: 5px"
                  type="textarea"
                  :rows="5"
                  @blur="onInputBlur"
                />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
    </el-main>

    <el-dialog title="添加組题" :visible.sync="addQuTableVisible" width="70%" center @close="resetQuestionForm">
      <el-card>
        <el-form ref="questionForm" :model="questionForm" :rules="addQuFormRules">
          <el-form-item label="试题类型" label-width="120px" prop="questionType">
            <el-select v-model="questionForm.questionType" placeholder="请选择" @change="questionForm.options = []">
              <el-option
                v-for="item in questionType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="试题内容" label-width="120px" prop="questionContent">
            <el-input
              v-model="questionForm.questionContent"
              style="margin-left: 5px"
              type="textarea"
              :rows="5"
            />
          </el-form-item>
          <el-form-item label="试题解析" label-width="120px" prop="analysis">
            <el-input
              v-model="questionForm.analysis"
              style="margin-left: 5px"
              type="textarea"
              :rows="5"
            />
          </el-form-item>
          <el-button
            type="primary"
            plain
            size="small"
            icon="el-icon-plus"
            style="margin-left: 40px"
            @click="addGroupQuestionAnswer"
          >
            添加问题选项
          </el-button>
          <!--存放答案表单的信息-->
          <el-form-item prop="options">
            <el-table :data="questionForm.options" border style="width: 96%;margin-left: 40px;margin-top: 10px">
              <el-table-column label="是否答案" width="80" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.correct"
                    @change="checked=>checkGroupQuestionAnswer(checked,scope.row.id)"
                  >答案</el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="选项内容">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.content"
                    style="margin-left: 5px"
                    type="textarea"
                    :rows="5"
                  />
                </template>
              </el-table-column>
              <el-table-column label="选项解析">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.analysis"
                    style="margin-left: 5px"
                    type="textarea"
                    :rows="5"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80" align="center">
                <template slot-scope="scope">
                  <el-button type="danger" icon="el-icon-delete" circle @click="delAnswer(scope.row.id)" />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addGroupQuestion">添 加</el-button>
        <el-button @click="addQuTableVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看組题" :visible.sync="groupQuestionVisible" width="70%" center @close="resetQuestionForm">
      <el-table :data="addQuForm.children" border style="width: 96%;margin-left: 40px;margin-top: 10px">
        <el-table-column label="题目" prop="questionContent" />
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/code'
import Editor from '@tinymce/tinymce-vue'

import { getSubjectKV, addQuestion } from '@/api/exam'
import {uploadFile} from "@/api/file";

export default {
  name: 'ExamQuestionAdd',
  components: { Editor },
  data() {
    return {
      tinymceHtml: '请输入内容',
      init: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide',
        height: 300,
        external_plugins: {
          'mathjax': '/tinymce/mathjax/plugin.min.js'
        },
        mathjax: {
          lib: '/tinymce/mathjax/tex-mml-chtml.js'
        },
        plugins: 'image code',
        toolbar: 'mathjax bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | image code | removeformat',
        branding: false,
        images_upload_handler: function(blobInfo, success, failure) {
          // base64转本地上传
          // 这个函数主要处理word中的图片，并自动完成上传；
          // 自己定义的一个函数；在回调中，记得调用success函数，传入上传好的图片地址；
          // blobInfo.blob() 得到图片的file对象；
          const file = blobInfo.blob()
          const formData = new FormData()
          formData.append('file', file)
          uploadFile(formData).then(resp => {
            if (resp.code === 0) {
              success(resp.data.url)
            } else {
              failure(resp.msg)
            }
          }).catch(err => {
            failure(err)
          }).finally(() => {
          })
        }
      },
      // 试题类型
      questionType: [
        {
          id: 1,
          name: '单选题'
        },
        {
          id: 2,
          name: '多选题'
        },
        {
          id: 3,
          name: '不定项选择题'
        },
        {
          id: 4,
          name: '判断题'
        },
        {
          id: 5,
          name: '填空题'
        },
        {
          id: 6,
          name: '问答题'
        },
        {
          id: 7,
          name: '理解题'
        },
        {
          id: 8,
          name: '組题'
        }
      ],
      // 题库信息
      allSubject: [],
      // 是否显示添加试题的对话框
      addQuTableVisible: false,
      // 添加试题的表单信息
      addQuForm: {
        questionType: 1,
        questionLevel: 1,
        subjectId: '',
        questionContent: '',
        images: [],
        analysis: '',
        createPerson: localStorage.getItem('username'),
        // 答案对象
        options: [],
        children: []
      },
      // 添加试题表单的验证规则
      addQuFormRules: {
        questionType: [
          {
            required: true,
            message: '请选择问题类型',
            trigger: 'blur'
          }
        ],
        questionLevel: [
          {
            required: true,
            message: '请选择问题难度',
            trigger: 'blur'
          }
        ],
        subjectId: [
          {
            required: true,
            message: '请选择题库',
            trigger: 'blur'
          }
        ],
        questionContent: [
          {
            required: true,
            message: '请输入题库内容',
            trigger: 'blur'
          }
        ]
      },
      questionForm: {
        questionType: 1,
        questionLevel: 1,
        subjectId: '',
        questionContent: '',
        images: [],
        analysis: '',
        // 答案对象
        options: []
      },
      groupQuestionVisible: false,
      answerContent4: null,
      answerContent8: null
    }
  },
  created() {
    document.title = '添加试题'
    this.getQuestionBankInfo()
  },
  methods: {
    onRadioChange(val) {
      let correct = false
      if (val === 0) {
        correct = true
      }
      this.addQuForm.options[0] = {
        id: this.addQuForm.options.length,
        correct: correct,
        content: '',
        pos: this.addQuForm.options.length + 1,
        images: [],
        analysis: ''
      }
    },
    onInputBlur() {
      this.addQuForm.options[0] = {
        id: this.addQuForm.options.length,
        correct: true,
        content: this.answerContent8,
        pos: this.addQuForm.options.length + 1,
        images: [],
        analysis: ''
      }
    },
    // 获取所有的题库信息
    getQuestionBankInfo() {
      getSubjectKV().then((resp) => {
        if (resp.code === 0) {
          this.allSubject = resp.data
        }
      })
    },
    // ****************************************************************************************************************
    // 表单信息重置
    resetQuestionForm() {
      this.$refs['questionForm'].resetFields()
      this.answerContent = ''
    },
    resetAddQuForm() {
      this.$refs['addQuForm'].resetFields()
      this.addQuForm.options = []
      this.answerContent = ''
    },
    // ****************************************************************************************************************

    // 新增试题中的新增答案填写框
    addOptionAnswer() {
      this.addQuForm.options.push({
        id: this.addQuForm.options.length,
        correct: false,
        content: '',
        pos: this.addQuForm.options.length + 1,
        images: [],
        analysis: ''
      })
    },
    addAnswer() {
      console.log('添加答案')
      this.addQuForm.options.push({
        id: this.addQuForm.options.length,
        correct: true,
        content: '',
        pos: this.addQuForm.options.length + 1,
        images: [],
        analysis: ''
      })
    },
    addGroupQuestionAnswer() {
      this.questionForm.options.push({
        id: this.questionForm.options.length,
        correct: false,
        content: '',
        pos: this.questionForm.options.length,
        images: [],
        analysis: ''
      })
    },
    // 新增试题中的删除答案填写框
    delAnswer(id) { // 当前答案的id
      this.addQuForm.options.map((item, index) => {
        if (item.id === id) this.addQuForm.options.splice(index, 1)
      })
    },
    // 答案上传照片了
    uploadAnswerImgSuccess(response, id) {
      this.addQuForm.options[id].images.push(response.data)
    },
    // 答案上传成功后删除
    handleAnswerRemove(file) {
      this.addQuForm.options.images.map((item, index) => { // 移除图片在表单中的数据
        if (item === file.response.data) this.addQuForm.images.splice(index, 1)
      })
    },
    // 选择正确答案的按钮变化事件
    checkAnswer(checked, id) {
      if (checked) {
        if (this.addQuForm.questionType === 1 || this.addQuForm.questionType === 3) { // 单选或者判断
          // 当前已经有一个正确的选择了
          this.addQuForm.options.map(item => {
            item.correct = false
          })
          this.addQuForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        } else { // 多选 可以有多个答案
          this.addQuForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        }
      } else {
        this.addQuForm.options.map(item => {
          if (item.id === id) item.correct = false
        })
      }
    },
    checkGroupQuestionAnswer(checked, id) {
      if (checked) {
        if (this.questionForm.questionType === 1 || this.questionForm.questionType === 3) { // 单选或者判断
          // 当前已经有一个正确的选择了
          this.questionForm.options.map(item => {
            item.correct = false
          })
          this.questionForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        } else { // 多选 可以有多个答案
          this.questionForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        }
      } else {
        this.questionForm.options.map(item => {
          if (item.id === id) item.correct = false
        })
      }
    },
    // 提交试题
    submitQuestion() {
      this.$refs['addQuForm'].validate((valid) => {
        if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }

        if (this.addQuForm.questionType !== 8) {
          if (!this.addQuForm.options.some(item => item.correct)) {
            // 无答案
            this.$message.error('必须提供答案')
            return false
          }
        }

        this.submitExamQuestion()
      })
    },
    submitExamQuestion() {
      addQuestion(this.addQuForm).then((resp) => {
        if (resp.code === 0) {
          this.resetAddQuForm()
          this.$notify({
            title: 'Tips',
            message: '试题已添加',
            type: 'success',
            duration: 2000
          })
        } else {
          this.$notify({
            title: 'Tips',
            message: resp.msg,
            type: 'success',
            duration: 2000
          })
        }
      })
    },
    addGroupQuestion() {
      this.$refs['questionForm'].validate((valid) => {
        console.log(this.questionForm.options.some(item => item.correct))
        if (!this.questionForm.options.some(item => item.correct)) {
          // 无答案
          this.$message.error('必须有一个答案')
          return false
        }

        this.addQuForm.children.push(Object.assign({}, this.questionForm))
        this.$notify({
          title: 'Tips',
          message: '組题已添加, 可以继续添加下一道题',
          type: 'success',
          duration: 3000
        })
        this.resetQuestionForm()
        console.log(this.addQuForm)
      })
    },
    viewGroupQuestion() {
      this.groupQuestionVisible = true
    },
    clear() {
      this.resetAddQuForm()
      this.$notify({
        title: 'Tips',
        message: '已清空',
        type: 'success',
        duration: 500
      })
    }
  }
}
</script>

<style>
</style>
